<template>
  <!-- <section class="px-4 grid md:grid-cols-6 gap-2 mt-16">
    <div class="col-span-4">
      <div class="mt-2 text-xl sm:text-4xl font-semibold tracking-tight text-gray-900">
        About us
      </div>
      <p class="font-semibold mt-4">Tools for drumming</p>
    </div>
    <div class="col-span-2">
      <div class="mt-2 text-xl sm:text-4xl font-semibold tracking-tight text-gray-900">Follow us</div>
      <div class="mt-4 flex">
        <a target="_blank" href="https://www.instagram.com/drumrudimentsapp/">
          <SvgIg class="h-10 w-10 mr-4" />
        </a>
        <a target="_blank" href="https://www.facebook.com/profile.php?id=61562364767248">
          <SvgFb class="h-10 w-10 mr-4" />
        </a>
        <a target="_blank" href="https://www.youtube.com/channel/UCl30qjRjHIKnj370VpE-aTQ">
          <SvgYt class="h-10 w-10" />
        </a>
        <a target="_blank" href="https://tiktok.com">
          <SvgTk class="h-10 w-10" />
        </a>
      </div>
    </div>
  </section> -->
  <section class="my-16 text-center">
    <div class="mt-2 text-2xl sm:text-4xl font-semibold tracking-tight text-gray-900">Follow us</div>
    <div class="flex justify-center mt-4">
      <a target="_blank" href="https://www.instagram.com/drumrudimentsapp/">
        <SvgIg class="h-10 w-10 mr-4" />
      </a>
      <a target="_blank" href="https://www.facebook.com/drumrudimentsapp/">
        <SvgFb class="h-10 w-10 mr-4" />
      </a>
      <a target="_blank" href="https://www.youtube.com/channel/UCl30qjRjHIKnj370VpE-aTQ">
        <SvgYt class="h-10 w-10" />
      </a>
    </div>
  </section>
</template>